<template>
    <div>


        <!-- Navigation -->
        <nav class="navbar navbar-expand-md fixed-top dm_bar_shadow dm_top_nav">
            <div class="container">
                <a class="navbar-brand" href="/dashboard"><img class="w-50 h-50 dm_logo" src="../../assets/img/logo.png"
                        alt=""></a>
                <button class="navbar-toggler d-none" type="button" data-toggle="offcanvas" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse offcanvas-collapse d-none d-md-block" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link dm_fa_icons p-0 m-2" href="/dashboard" title="Home/Feeds"><i class="fas fa-home"></i></a>
                        </li>
                        <li class="nav-item active">
                            <a class="nav-link dm_fa_icons p-0 m-2" @click="openProfile" title="Profile"><i
                                    class="fas fa-user w-50"></i></a>
                        </li>
                        <li class="nav-item position-relative">
                            <a class="nav-link dm_fa_icons p-0 m-2" href="/chat" title="Message"><i class="fas fa-comment-alt"></i>
                                <div v-if="message_count > 0" class="dm_noti_count text-center">{{ message_count }}
                                </div>
                            </a>
                        </li>
                        <li class="nav-item position-relative dropdown">
                            <a class="nav-link dm_fa_icons p-0 m-2 dropdown-toggle" href="#" role="button" title="Notifications" 
                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-bell"></i>
                                <div v-if="notification_count > 0" class="dm_noti_count text-center">
                                    {{ notification_count }}
                                </div>
                                <div class="dropdown-menu dm_card_shadow dm_notif_dropdown" aria-labelledby="dropdownMenuLink"
                                    v-if="notifications.length > 0 || notifications_seen.length > 0">
                                    <h4 class="text-center pt-2 pb-3">
                                        New Notifications
                                    </h4>
                                    <span v-for="list in notifications" v-bind:key="list.id">
                                        <div class="dropdown-item p-3">
                                            <div class="row">
                                            <div class="col-12 d-flex justify-content-between pb-3">
                                            <a class="dm_noti_msg" @click="openPostById(list.model_id)">{{ list.message }}</a>
                                            <a @click="updateToSeen(list.id)"><i class="fas fa-eye"></i></a><br>
                                            </div>
                                            <div class="col-12 dm_noti_date">
                                            <i class="fas fa-clock"></i> {{ frontEndDateFormatTime(list.created_at) }}
                                            </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </span>
                                    <h4 class="text-center pt-2 pb-3">
                                        Seen Notifications
                                    </h4>
                                    <span v-for="list in notifications_seen" v-bind:key="list.id">
                                        <a class="dropdown-item p-3">
                                            <div class="row">
                                            <div class="col-12 d-flex justify-content-between pb-3">
                                            <a class="dm_noti_msg" @click="openPostById(list.model_id)">{{ list.message }}</a>
                                            <a @click="deleteNotification(list.id)"><i class="fas fa-trash"></i></a><br>
                                            </div>
                                            <div class="col-12">
                                            <i class="fas fa-clock"></i> {{ frontEndDateFormatTime(list.created_at) }}
                                            </div>
                                            </div>
                                        </a>
                                        <hr>
                                    </span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dm_fa_icons p-0 m-2" href="/settings" title="Settings"><i class="fas fa-cog"></i></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dm_fa_icons p-0 m-2" @click="logout" title="Logout"><i class="fas fa-sign-out-alt"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!--Bottom Navigation-->
        <nav class="d-flex d-md-none dm_bar_shadow dm_bottom_nav fixed-bottom">
            <ul class="nav nav-pills nav-justified">
                <li class="nav-item position-relative">
                            <a class="nav-link dm_fa_icons p-0 m-0" href="/dashboard" ><i class="fas fa-home"></i></a>
                        </li>
                <li class="nav-item my-profile">
                    <a class="nav-link dm_fa_icons p-0 m-0" @click="openProfile"><i
                            class="fas fa-user w-50"></i></a>
                </li>
                <li class="nav-item position-relative chat">
                    <a class="nav-link dm_fa_icons p-0 m-0" href="/chat"><i class="fas fa-comment-alt"></i>
                        <div v-if="message_count > 0" class="dm_tab_noti_count text-center">{{ message_count }}
                        </div>
                    </a>
                </li>
                <li class="nav-item position-relative dropdown">
                    <a class="nav-link dm_fa_icons p-0 m-0 dropdown-toggle" href="#" role="button"
                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-bell"></i>
                        <div v-if="notification_count > 0" class="dm_tab_noti_count text-center">
                            {{ notification_count }}
                        </div>
                        <div class="dropdown-menu dm_card_shadow dm_mobile_notif_dropdown" aria-labelledby="dropdownMenuLink"
                                    v-if="notifications.length > 0 || notifications_seen.length > 0">
                                    <h4 class="text-center pt-2 pb-3">
                                        New Notifications
                                    </h4>
                                    <span v-for="list in notifications" v-bind:key="list.id">
                                        <div class="dropdown-item p-3">
                                            <div class="row">
                                            <div class="col-12 d-flex justify-content-between pb-3">
                                            <a class="dm_noti_msg" @click="openPostById(list.model_id)">{{ list.message }}</a>
                                            <a @click="updateToSeen(list.id)"><i class="fas fa-eye"></i></a><br>
                                            </div>
                                            <div class="col-12 dm_noti_date">
                                            <i class="fas fa-clock"></i> {{ frontEndDateFormatTime(list.created_at) }}
                                            </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </span>
                                    <h4 class="text-center pt-2 pb-3">
                                        Seen Notifications
                                    </h4>
                                    <span v-for="list in notifications_seen" v-bind:key="list.id">
                                        <a class="dropdown-item p-3">
                                            <div class="row">
                                            <div class="col-12 d-flex justify-content-between pb-3">
                                            <a class="dm_noti_msg" @click="openPostById(list.model_id)">{{ list.message }}</a>
                                            <a @click="deleteNotification(list.id)"><i class="fas fa-trash"></i></a><br>
                                            </div>
                                            <div class="col-12">
                                            <i class="fas fa-clock"></i> {{ frontEndDateFormatTime(list.created_at) }}
                                            </div>
                                            </div>
                                        </a>
                                        <hr>
                                    </span>
                                </div>
                    </a>
                </li>
                <li class="nav-item settings">
                    <a class="nav-link dm_fa_icons p-0 m-0" href="/settings"><i class="fas fa-cog"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link dm_fa_icons p-0 m-0" @click="logout"><i
                            class="fas fa-sign-out-alt"></i></a>
                </li>
              </ul>
        </nav>
    </div>
</template>
<script>
    export default {
        name: "header-vue",
        data: () => ({
            notifications: [],
            notifications_seen: [],
            notification_count: 0,
            message_count: 0,
        }),
        sockets: {
            notification: function () {
                this.getUserNotifications();
                this.$forceUpdate();
            },
        },
        methods: {
            openProfile: function () {
                localStorage.setItem('user_id', localStorage.getItem('user'));
                this.$router.push({
                    path: 'my-profile'
                })
            },
            logout: function () {
                localStorage.setItem('user', '');
                localStorage.setItem('token', '');
                this.$router.push({
                    path: '/'
                })
            },
            frontEndDateFormatTime: function (dates) {
                let date = window.moment(dates);
                return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY hh:mm A');
            },
            openPostById: function (id) {
                localStorage.setItem('user_id', localStorage.getItem('user'));
                this.$router.push({
                    path: '/'
                })
                this.$router.push({
                    path: 'feed/' + id
                })
            },
            async getUserNotifications() {
                let responce = await this.$store.dispatch("userNotifications");
                if (responce.status) {
                    this.notifications = responce.data.data['unseen'];
                    this.notification_count = responce.data.data['notification_unseen_count'];
                    this.message_count = responce.data.data['message_unseen_count'];
                    this.notifications_seen = responce.data.data['seen'];
                }
            },
            async updateToSeen(id) {
                let form = {
                    notification_id: id
                };
                let responce = await this.$store.dispatch("updateToSeen", form);
                if (responce.status) {
                    this.getUserNotifications();
                    this.$forceUpdate();
                }
            },
            async deleteNotification(id) {
                let form = {
                    notification_id: id
                };
                let responce = await this.$store.dispatch("deleteNotification", form);
                if (responce.status) {
                    this.getUserNotifications();
                    this.$forceUpdate();
                }
            },
        },
        created() {
            this.getUserNotifications();
            var _this = this;
            window.setTimeout(function(){
                var route = _this.$route.path;
                var arr = route.split("/");
                var class1 = arr[1];
                window.$('.nav-item').removeClass('active');
                window.$('.'+class1).addClass('active');
            }, 500);
        }
    };
</script>
<template>

  <!-- Footer -->
    <footer class="">
        <!-- <div class="container">
            <p class="m-0 text-center text-white">Copyright &copy; Your Website 2019</p>
        </div> -->
        <!-- /.container -->
        <!-- Modal 1 -->
        <div class="modal fade dm_reg_modal_wrapper" id="subscription_modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content border-0">
              <div class="row justify-content-center">
                <div class="col-md-10 col-12">
                    <div class="modal-header justify-content-center border-0 px-md-0 px-3">
                        <img class="dm_logo" src="../../assets/img/logo.png" alt="">
                    </div>
                    <div class="modal-body px-md-0 px-3">
                        <h4 class="mb-12 text-primary">
                            Sorry you cant access this area, Please buy Subscription to use this feature.
                        </h4>
                    </div>
                    <div class="modal-footer border-0 pb-4 px-md-0 px-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="dm_btn_wraper d-flex justify-content-between align-items-center">
                                <a href="/subscription" class="dm_btn_med_solid py-3">Subscribe</a>
                                <button type="button" class="dm_btn_dark_outline py-3" data-dismiss="modal">Cancel</button>
                            </div>
                          </div>
                        </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </footer>

</template>
<script>

export default {
    name: "header-vue",
    data: () => ({
    })
};
</script>

<script>
$(function () {
  'use strict'

  $('[data-toggle="offcanvas"]').on('click', function () {
    $('.offcanvas-collapse').toggleClass('open')
  })
})
</script>